<template>
    <div class="chat__wrap" :class="{'hide': !showSidebar}">

        <NewConversationSidebar
            v-if="showNewConversation && company"
            :isSmallDevice="isSmallDevice"
        >
        </NewConversationSidebar>

        <div class="chat__body" v-if="!showNewConversation">
            <ul class="chat__list">

                <li class="chat__item" v-for="(candidate, index) in pageState.candidates" v-bind:key="index" :class="{'active': highlightCandidate(candidate.id), 'applicant-unseen-message': isMessageUnseen(candidate.message.is_unseen)}">
                    <a @click="moveToConversationPage(candidate.id)" class="chat__item__block" style="cursor: pointer">
                        <div class="chat__thumb">
                            <message-user-picture v-if="company" :src="candidate.user.image" :alt="candidate.user.email"></message-user-picture>
                            <message-user-picture v-else :src="candidate.company.logo" :alt="candidate.company.name"></message-user-picture>
                        </div>
                        <div class="chat__content">
                            <template v-if="company">
                                <div class="title">
                                    <span class="name">{{ candidate.user.name }}</span>
                                    <span class="time">{{ candidate.message.created_at }}</span>
                                </div>
                                <span class="designation" v-text="jobTitleLimit(candidate.job.title)"></span>
                            </template>
                            <template v-else>
                                <div class="title">
                                    <span class="name" v-text="jobTitleLimit(candidate.job.title)"></span>
                                    <span class="time">{{ candidate.message.created_at }}</span>
                                </div>
                                <span class="designation">{{ candidate.company.name }}</span>
                            </template>
                            <span class="text" v-html="candidate.message.message"></span>
                        </div>
                    </a>
                </li>

                <li v-if="isEmptyCandidate" class="no__candidate text-center">
                    {{ $t('No candidates or jobs found.') }}
                </li>

                <infinite-loading v-if="totalCandidate > 15" :identifier="infiniteKey" spinner="waveDots" @infinite="getHasConversationApplicantList">
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </ul>
        </div>

    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import {mapState, mapActions} from "vuex";
import {EventBus} from "../../extra/event-bus";
import {CONVERSATION_SIDEBAR_TOGGLE} from "../../constants/events";
import { stringLimit } from "../../extra/helper";
import MessageUserPicture from "./MessageUserPicture";
import NewConversationSidebar from "./NewConversationSidebar";
import {LOAD_CONVERSATION_CANDIDATES} from "../../constants/action-type";

export default {
    name: "ConversationSidebar",

    components: {
        MessageUserPicture,
        InfiniteLoading,
        NewConversationSidebar
    },

    props: [
        'search',
        'showSidebar',
        'isSmallDevice',
        'infiniteKey'
    ],

    data() {
        return {
            form: {
                'search': ''
            }
        }
    },

    computed: {

        ...mapState(['company', 'pageState']),

        isEmptyCandidate() {
            return this.pageState.candidates.length === 0;
        },

        applicantId() {
            return this.pageState.applicantId;
        },

        totalCandidate() {
            return this.pageState.totalCandidate;
        },

        candidatePage() {
            return this.pageState.candidatePage;
        },

        candidateLastPage() {
            return this.pageState.candidateLastPage;
        },

        showNewConversation() {
            return this.pageState.showNewConversation;
        }

    },

    methods: {
        ...mapActions([LOAD_CONVERSATION_CANDIDATES]),

        moveToConversationPage(candidateId) {
            let movePage = 'applicantConversations';
            if(this.company) {
                movePage = 'conversations';
            }

            if(this.isSmallDevice) {
                EventBus.$emit(CONVERSATION_SIDEBAR_TOGGLE);
            }
            this.$router.push({name: movePage, params: { id: candidateId }});
        },

        async getHasConversationApplicantList($state) {

            await this[LOAD_CONVERSATION_CANDIDATES]({params: {page: this.candidatePage, ...this.form, 'has-conversation': true}, appendCandidate: true});

            $state.loaded();

            if (this.candidatePage > this.candidateLastPage) {
                $state.complete();
            }
        },

        highlightCandidate(candidateId) {
            return candidateId === parseInt(this.applicantId);
        },

        jobTitleLimit(value, size = 50) {
            return stringLimit(value, size);
        },

        isMessageUnseen(is_unseen) {
            return is_unseen;
        }
    },

    created() {
        this.form.search = this.search;
    }
}
</script>

<style scoped>
    .no__candidate {
        color: #7d8091;
        font-size: 14px;
    }
    .message__wrapper .chat__wrap{
        height:calc(100vh - 300px);
        min-height: 550px;
        display:flex;
        flex-direction:column;
        overflow: hidden;
        position: relative;
    }
    @media all and (max-width: 1600px){
        .message__wrapper .chat__wrap{
            height:calc(100vh - 295px)
        }
    }
    @media all and (max-width: 1199px){
        .message__wrapper .chat__wrap{
            height:calc(100vh - 246px)
        }
    }
    @media all and (max-width: 767px){
        .message__wrapper .chat__wrap{
            height:calc(100vh - 173px)
        }
    }
    .message__wrapper .chat__wrap .new__message__wrap {
        position: absolute;
        top: 0;
        left: -100%;
        width: 350px;
        bottom: 0;
        transition: left .3s ease;
        background: #fff;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }
    @media all and (max-width: 1399px) {
        .message__wrapper .chat__wrap .new__message__wrap {
            width: 300px;
        }
    }
    @media all and (max-width: 767px) {
        .message__wrapper .chat__wrap .new__message__wrap {
            width: 100%;
        }
    }
    .message__wrapper .chat__wrap .new__message__wrap.show {
        left: 0;
    }
    .message__wrapper .chat__wrap .new__message__wrap.show + .chat__body {
        opacity: 0;
        visibility: hidden;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box {
        padding: 20px;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box .title .back {
        margin-right: 12px;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box .title h3 {
        font-size: 16px;
        font-weight: 500;
        color: #1c2238;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box input {
        background: rgba(89, 125, 252, .1);
        height: 40px;
        border-radius: 20px;
        width: 100%;
        padding: 0 15px;
        font-size: 14px;
    }
    .new__message__wrap .message__search__results {
        flex: 1;
        overflow: auto;
    }
    .message__search__results .chat__list {
        padding: 0 20px 10px;
    }
    .message__search__results .chat__list .chat__item:not(:last-child) {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(47,193,225,.15);
    }
    .message__search__results .chat__list .chat__item .chat__item__block {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 10px;
    }
    .message__search__results .chat__list .chat__item .chat__item__block:hover {
        background: rgba(47,193,225,.07);
    }
    .message__search__results .chat__list .chat__item .chat__item__block .chat__thumb {
        flex: 0 0 40px;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }
    .message__search__results .chat__list .chat__item .chat__item__block .chat__thumb img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
    .message__search__results .chat__list .chat__item .chat__item__block .chat__content .name {
        font-size: 14px;
        display: block;
        font-weight: 500;
        line-height: 1;
        color: #597dfc;
        margin-right: auto;
        margin-bottom: 3px;
    }
    .message__search__results .chat__list .chat__item .chat__item__block .chat__content .job__title {
        display: block;
        color: #1c2238;
        font-size: 12px;
    }
    .message__wrapper .chat__wrap .chat__head{
        padding:20px;
        border-bottom:1px solid #f5f7fd;
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        gap:15px
    }
    .message__wrapper .chat__wrap .chat__head .chat__search{
        -webkit-box-flex:1;
        -ms-flex:1 1 auto;
        flex:1 1 auto
    }
    .message__wrapper .chat__wrap .chat__head .chat__search .form-control{
        background:#f5f7fd;
        height:40px !important;
        font-size:14px;
        font-weight:400
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter{
        -webkit-box-flex:0;
        -ms-flex:0 0 40px;
        flex:0 0 40px
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .button{
        padding:0;
        height:40px;
        width:40px;
        line-height:40px;
        min-height:40px
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .button:after{
        display:none
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu{
        position:absolute;
        left:auto;
        right:0;
        top:100% !important;
        -webkit-transform:unset !important;
        transform:unset !important;
        left:auto !important;
        padding:0;
        -webkit-box-shadow:0 10px 10px rgba(125,128,145,0.3);
        box-shadow:0 10px 10px rgba(125,128,145,0.3);
        border-radius:5px;
        border:0;
        min-width:260px
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu .form-group{
        margin-bottom:10px
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu .form-group:last-child{
        margin-bottom:0
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu .form-group .select-option{
        height:auto
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu .form-group .select-option::after{
        right:15px
    }
    .message__wrapper .chat__wrap .chat__head .chat__filter .dropdown-menu .form-group .select-option select{
        height:40px;
        padding: 0 30px 0 15px;
    }
    .message__wrapper .chat__wrap .chat__body{
        flex:1;
        overflow-y:auto;
        padding:10px;
        transition: opacity .3s ease;
    }
    .message__wrapper .chat__wrap .chat__body .chat__list{
        padding:0;
        margin:0;
        list-style:none
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item:not(:last-child){
        padding-bottom:5px;
        margin-bottom:5px;
        border-bottom: 1px solid rgba(47,193,225,.15);
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item.active .chat__item__block,
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item:hover .chat__item__block{
        color:inherit;
        background: #fff;
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        padding:10px;
        border-radius:10px;
        border:1px solid transparent
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__thumb{
        height:40px;
        width:40px;
        -webkit-box-flex:0;
        -ms-flex:0 0 40px;
        flex:0 0 40px;
        border-radius:50%;
        display:block;
        overflow:hidden;
        margin-right:12px
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__thumb img{
        height:100%;
        width:100%;
        -o-object-fit:cover;
        object-fit:cover
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content{
        -webkit-box-flex:1;
        -ms-flex:1 1 auto;
        flex:1 1 auto
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content .title{
        display:flex;
        align-items:flex-start;
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content .title .name{
        -webkit-box-flex:1;
        -ms-flex:1 1 auto;
        flex:1 1 auto;
        font-size:14px;
        display:block;
        font-weight:500;
        line-height:1;
        color:#597dfc;
        margin-right:auto
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content .title .time{
        font-size:10px;
        color:#7d8091;
        -webkit-box-flex:0;
        -ms-flex:0 0 auto;
        flex:0 0 auto;
        text-align:right;
        padding-left:5px
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content .designation{
        display:block;
        color:#1c2238;
        font-size:12px
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .chat__content .text{
        font-size:12px;
        font-weight:400;
        color:#7d8091;
        line-height:1.2;
        display:block;
        margin-top:2px;
        word-break:break-all
    }
    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item .chat__item__block .status i{
        color:#7d8091
    }
    .message__wrapper .chat__wrap .message__search__results::-webkit-scrollbar,
    .message__wrapper .chat__wrap .chat__body::-webkit-scrollbar {
        width: 7px;
    }
    .message__wrapper .chat__wrap .message__search__results::-webkit-scrollbar-track,
    .message__wrapper .chat__wrap .chat__body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(125,128,145, 0.3);
        background-image: linear-gradient(to right bottom, rgba(89, 125, 252, 0.1), rgba(89, 125, 252, 0.1));
    }
    .message__wrapper .chat__wrap .message__search__results::-webkit-scrollbar-thumb,
    .message__wrapper .chat__wrap .chat__body::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #597dfc;
    }


    .message__wrapper .chat__wrap .new__message__wrap .search__box .search__wrap {
        position: relative;
    }
    .message__wrapper .chat__wrap .new__message__wrap .search__box .search__remove {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 10px;
        background: #ff5f74;
        cursor: pointer;
        color: #fff;
        font-weight: bold;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
    }

    .message__search__results .chat__list .chat__item.active .chat__item__block,
    .message__search__results .chat__list .chat__item:hover .chat__item__block {
        color: inherit;
        background: rgba(47,193,225,.07);
    }

    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item.applicant-unseen-message .chat__item__block {
        border-left: 2px solid #597dfc;
    }

    .message__wrapper .chat__wrap .chat__body .chat__list .chat__item.applicant-unseen-message .chat__item__block .chat__content .text {
        font-weight: 600;
    }

</style>
